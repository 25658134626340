import cx from "classnames";
import { useRouter } from "next/router";
import { ReactElement, ReactNode } from "react";

import Image from "../../../../../apps/web-client/src/components/common/Image";
import { ArrowIcon } from "../../atoms/Icons";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import * as s from "./styles";

interface Props {
  imagePath: string;
  imageAlt: string;
  showBackLink?: boolean;
  smallPadding?: boolean;
  surtitle?: string;
  title?: string;
  subtitle?: string | ReactElement;
  titleIcon?: JSX.Element;
  titleIconRight?: JSX.Element;
  children?: ReactNode;
}

export default function BackgroundHeader({
  showBackLink = true,
  imagePath,
  imageAlt,
  surtitle,
  titleIcon,
  titleIconRight,
  title,
  children,
  subtitle,
  smallPadding = false,
}: Props): JSX.Element {
  const { back } = useRouter();
  const showTitlesContainer = surtitle || title || subtitle;
  const canNavigateBack = window.history.length > 1;

  return (
    <header className={s.header}>
      <Image className="object-cover" fill src={imagePath} alt={imageAlt} />

      <div className={s.wrapper}>
        <div
          className={cx(s.container, {
            "pb-8": !children && !subtitle,
            "pt-4 pb-8": smallPadding,
            "py-8 md:py-20": !smallPadding,
          })}
        >
          {showBackLink && canNavigateBack && (
            <Button
              className={s.backArrow}
              variant="tertiaryInverse"
              onClick={back}
              onKeyDown={back}
              data-testid="back-button"
            >
              <ArrowIcon className="mr-2" direction="left" />
              Back
            </Button>
          )}
          {showTitlesContainer ? (
            <div className={s.titlesContainer}>
              {surtitle && (
                <Text variant="h3" color="white" gutter="sm">
                  {surtitle}
                </Text>
              )}
              {title && (
                <span className="flex items-center gap-x-4">
                  {titleIcon}
                  <Text variant="h1" color="white">
                    {title}
                  </Text>
                  {titleIconRight}
                </span>
              )}
              {subtitle && (
                <Text color="light" weight="medium">
                  {subtitle}
                </Text>
              )}
            </div>
          ) : null}
          {children}
        </div>
      </div>
    </header>
  );
}
