import ctl from "@netlify/classnames-template-literals";

export const container = ctl(`
  relative
  flex
  flex-col
  items-center
  justify-center
  gap-y-12
  w-full
  h-full
  text-white
  bg-gradient-ellipse-br
  from-bg-purple-500
  via-bg-purple-800
  to-bg-purple-900
`);
