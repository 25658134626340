import ctl from "@netlify/classnames-template-literals";

export const cardSecondary = ctl(`
  flex
  flex-col
  justify-between
  rounded-xl
  lg:flex-row
`);

export const contentWrapper = ctl(`
  flex
  flex-col
  items-center
  lg:flex-row
`);

export const imageWrapper = ctl(`
  mb-4
  mr-4
  shrink-0
  lg:mb-0
  lg:mr-8
`);

export const content = ctl(`
  flex
  flex-col
  w-full
  lg:mb-0
`);

export const buttonWrapper = ctl(`
  justify-center
  flex
  w-full
  items-center
  lg:shrink-0
  lg:w-52
  lg:ml-8
`);
