/* eslint-disable no-console */
import { datadogLogs, type LogsEvent } from "@datadog/browser-logs";
import { datadogRum, DefaultPrivacyLevel } from "@datadog/browser-rum";
import { DATADOG_ALLOWED_ERRORS_REGEX } from "@olivahealth/constants";

const isBrowser = typeof window !== "undefined";

const env = process.env.NEXT_PUBLIC_ENVIRONMENT || "local";
const service = process.env.NEXT_PUBLIC_SERVICE || "frontend";
const version = process.env.NEXT_PUBLIC_VERSION || "local-development";

const shouldEnableLogs = env !== "local";
const shouldEnableRUM = isBrowser && env === "production";

if (
  shouldEnableLogs &&
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DATADOG_SITE
) {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE,
    env,
    beforeSend: filterOutLogs,
    service,
    version,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["error", "warn"],
    forwardReports: "all",
    sessionSampleRate: 100,
  });

  if (env !== "production") {
    console.log("Datadog logs enabled!");
  }
}

if (
  shouldEnableRUM &&
  process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID &&
  process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DATADOG_SITE
) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE,
    env,
    service,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
    enableExperimentalFeatures: ["clickmap"],
  });
  if (env !== "production") {
    console.log("Datadog rum enabled!");
  }
  datadogRum.startSessionReplayRecording();
}

function filterOutLogs(log: LogsEvent): boolean {
  return !log.message.match(DATADOG_ALLOWED_ERRORS_REGEX);
}
