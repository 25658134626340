import { useRouter } from "next/router";
import cx from "classnames";
import { Button, Text } from "@olivahealth/oli-ui";
import { useMediaQuery } from "react-responsive";
import Logo from "@olivahealth/oli-ui/src/atoms/Logo";
import { ReactNode } from "react";
import Image from "../Image";
import * as s from "./styles";

export interface Props {
  buttonLabel?: string | null;
  buttonLink?: string;
  description?: ReactNode;
  onClickButton?: () => void;
  showIcon?: boolean;
  title: string;
  variant?: "light" | "dark";
}

export default function ErrorPage({
  buttonLabel,
  buttonLink,
  description,
  onClickButton,
  showIcon = true,
  title,
}: Props): JSX.Element {
  const { push } = useRouter();

  const showButton = buttonLabel && (buttonLink || onClickButton);

  const handleClickButton = () => {
    if (buttonLink) {
      return push(buttonLink);
    }

    if (onClickButton) {
      return onClickButton();
    }
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <div className={cx(s.container)}>
      <div className="absolute top-8 left-8">
        <Logo height={28} color="white" variant="full" />
      </div>

      {showIcon && (
        <Image
          src="/img/img_status_caution.png"
          alt={title}
          className="object-contain"
          height={96}
          width={96}
        />
      )}
      <Text variant={isTabletOrMobile ? "h3" : "h1"}>{title}</Text>
      <p>{description}</p>
      {showButton && (
        <Button variant="green" onClick={handleClickButton}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
}
