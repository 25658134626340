/* eslint-disable react/prop-types */

import { datadogRum } from "@datadog/browser-rum";
import logger from "@olivahealth/logger/client";
import React from "react";
import ErrorPage from "../ErrorPage";

interface Props {
  children?: React.ReactNode;
  fallback?: React.ReactNode | ((error: Error | null) => React.ReactNode);
}

interface State {
  hasError: boolean;
  error: null | Error;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    datadogRum.addError(error);
    logger.error("ErrorBoundary", "General error caught", {
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        if (typeof this.props.fallback === "function") {
          return this.props.fallback(this.state.error);
        }
        return this.props.fallback;
      }
      return (
        <ErrorPage
          title="Something went wrong"
          buttonLabel="Reload the page"
          onClickButton={() => window?.location?.reload()}
        />
      );
    }

    return this.props.children;
  }
}
