/* eslint-disable no-restricted-imports */
import NextImage, { ImageProps, ImageLoaderProps } from "next/image";
import { ENVIRONMENT } from "@olivahealth/constants";

const CDN_URLS = [
  {
    bucket: "oliva-resources.s3.eu-west-1.amazonaws.com",
    cdn: "resources.olivahealth.app",
  },
  {
    bucket: "oliva-resources-thumbnails.s3.eu-west-1.amazonaws.com",
    cdn: "thumbnails.olivahealth.app",
  },
  {
    bucket: "oliva-univers-assets.s3.eu-west-1.amazonaws.com",
    cdn: "assets.olivahealth.app",
  },
  {
    bucket: "resources-staging.s3.eu-west-3.amazonaws.com",
    cdn: "resources.staging.olivahealth.app",
  },
  {
    bucket: "resources-thumbnails-staging.s3.eu-west-3.amazonaws.com",
    cdn: "thumbnails.staging.olivahealth.app",
  },
];

export default function Image(props: ImageProps) {
  return (
    <NextImage
      {...props}
      {...(ENVIRONMENT !== "local" ? { loader: cloudflareLoader } : {})}
    />
  );
}

function normalizeSrc(src: string) {
  if (src.startsWith("/")) {
    return src.slice(1);
  }
  // If a bucket is found, replace it with the CDN URL
  for (const { bucket, cdn } of CDN_URLS) {
    if (src.includes(bucket)) {
      return src.replace(bucket, cdn);
    }
  }
  return src;
}

function cloudflareLoader({ src, width, quality }: ImageLoaderProps) {
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(",");

  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
}

export * from "next/image";
