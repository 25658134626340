import { ReactNode, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "@react-spring/web";

import Text, { TextSize } from "../Text";
import Divider from "../Divider";

import { ChevronIcon } from "../Icons";
import type { Variant } from "./styles";
import * as s from "./styles";
import styles from "./styles.module.css";

export interface Props {
  children?: ReactNode;
  defaultOpen?: boolean;
  icon?: ReactNode;
  iconOpen?: ReactNode;
  onItemClick?: (isOpen: boolean, title?: string) => void;
  title?: string;
  headerComponent?: ReactNode;
  variant?: Variant;
  disabled?: boolean;
  disabledIcon?: ReactNode;
  chevronIconPosition?: "left" | "right";
  fullWidth?: boolean;
  chevronColor?: string;
  chevronVerticalAlign?: "top" | "center";
  padding?: "none" | "sm" | "md" | "lg";
}

export default function Disclosure({
  children,
  defaultOpen = false,
  icon,
  iconOpen,
  chevronVerticalAlign = "center",
  onItemClick,
  title,
  headerComponent,
  variant = "default",
  disabled = false,
  padding = "md",
  disabledIcon,
  chevronIconPosition = "right",
  fullWidth,
  chevronColor,
}: Readonly<Props>): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [animationStart, setAnimationStart] = useState(false);
  const [showBorderRadius, setShowBorderRadius] = useState(!defaultOpen);
  const [style, animate] = useSpring(() => ({
    height: "0px",
    opacity: 0,
    onStart: () => {
      setAnimationStart(true);
    },
    onRest: () => {
      setAnimationStart(false);
    },
  }));

  // ResizeObserver to watch content changes
  useEffect(() => {
    const handleResize = () => {
      if (!isOpen || !ref.current) return;
      animate.set({
        height: `${ref.current.scrollHeight}px`,
      });
    };

    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [animate, isOpen]);

  useEffect(() => {
    const onAnimationEnd = !animationStart;
    if (onAnimationEnd && !isOpen) {
      setShowBorderRadius(true);
    } else {
      setShowBorderRadius(false);
    }
  }, [animationStart]);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    animate.start({
      height: isOpen && ref.current ? `${ref.current.scrollHeight}px` : "0px",
      opacity: isOpen ? 1 : 0,
    });
  }, [animate, isOpen]);

  const handleClickButton = () => {
    if (disabled) {
      return;
    }

    const newValue = !isOpen;
    onItemClick?.(newValue, title);
    setIsOpen(newValue);
  };

  let headerTextSize: TextSize = "base";
  if (variant === "tertiary") {
    headerTextSize = "xl";
  } else if (variant === "secondary") {
    headerTextSize = "2xl";
  }

  return (
    <div className={s.getDisclosureWrapper(variant)}>
      <button
        style={{ transition: "border-radius 0.2s ease" }}
        className={`${s.getButtonWrapper(
          variant,
          isOpen,
          disabled ? (!children ? "cursor-auto" : "cursor-not-allowed") : "",
          chevronIconPosition,
          chevronVerticalAlign,
          fullWidth,
          padding,
          showBorderRadius,
        )} ${variant === "glass" && `${styles.box} ${isOpen ? styles.tealBorder : styles.purpleBorder}`}`}
        onClick={handleClickButton}
      >
        {headerComponent ?? (
          <span className={s.header}>
            {icon && <span className="mr-2">{icon}</span>}
            <Text size={headerTextSize}>
              <strong>{title}</strong>
            </Text>
          </span>
        )}
        {getIcon(disabled, disabledIcon, isOpen, icon, iconOpen, chevronColor)}
      </button>
      <div className={s.getContentWrapper(variant)}>
        <animated.div className="w-full rounded-b-lg" style={style}>
          <div
            ref={ref}
            className={s.getContentInnerWrapper(variant, fullWidth)}
          >
            {variant === "secondary" ? <Divider margin="none" /> : null}
            {variant === "darkglass" ? (
              <Divider margin="none" variant="accent" />
            ) : null}
            <div className={s.getContentChildrenWrapper(variant)}>
              {children}
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
}

function getIcon(
  disabled: boolean,
  disabledIcon: ReactNode | undefined,
  isOpen: boolean,
  icon?: ReactNode,
  iconOpen?: ReactNode,
  chevronColor?: string,
) {
  if (disabled && disabledIcon !== undefined) {
    return disabledIcon;
  }

  if (icon || iconOpen) {
    return isOpen ? (iconOpen ?? icon) : icon;
  }

  return (
    <ChevronIcon
      direction="down"
      className={s.getChevronStyle(isOpen)}
      color={chevronColor}
    />
  );
}
